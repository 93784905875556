/*
 * @Author: AnstJJ
 * @Date: 2024-05-20 15:07:48
 * @LastEditors: AnstJJ
 * @LastEditTime: 2025-03-07 15:07:30
 * @Description: 请填写简介
 */
import request from './request';

let version1 = '/v1';
let version2 = '/v2';
let version3 = '/v3';

/**
 * 其他登录（第三方）
 * @param data
 */
export function otherLogin(data) {
  return request({
    url: version2 + '/users/otherlogin',
    method: 'POST',
    data
  })
}

/**
 * 获取微信信息
 * @param code
 */
export function wxUserInfo(code) {
  let params = { code: code };
  return request({
    url: version2 + '/users/wxuserinfo',
    method: 'GET',
    params
  })
}


/**
 * 获取微信用户信息pro
 */
export function wxuserinfopro(params) {
  return request({
    url: version2 + '/users/wxuserinfopro',
    method: 'GET',
    params,
  })
}


/**
 * 获取用户信息
 */
export function getUser() {
  return request({
    url: version2 + '/users',
    method: 'GET'
  })
}
/**
 * 获取落地页页面配置
 */
export function getActivityPage(page_id) {
  console.log(page_id, 'page_id')
  return request({
    url: version2 + '/activity/page/' + page_id,
    method: 'GET',
  })
}

// 生成小程序短链
export function miniShortLink(params) {
  return request({
    url: version2 + `/mini/short/link/data`,
    method: 'GET',
    params,
  })
}


/**
 * 通过订单号获取落地页客服和助教
 */
export function activitySimple(params) {
  return request({
    url: version2 + '/activity/simple',
    method: 'GET',
    params,
  })
}

/**
 * 第三方创建订单微信支付
 */

export function thirdPartyOrders(data) {
  return request({
    url: version2 + '/thirdPartyOrders',
    method: 'POST',
    data,
  })
}

/**
 * 日志记录
 */

export function errLog(data) {
  return request({
    url: version2 + '/wlog',
    method: 'POST',
    data,
  })
}



/**
 * 公众号微信支付
 */

export function thirdPartyJsApiOrders(data) {
  return request({
    url: version2 + '/thirdPartyJsApiOrders',
    method: 'POST',
    data,
  })
}

/**
 * 获取手机验证码
 */
export function getMobileCode(nationcode, mobile) {
  let data = {
    "mobile": mobile,
    "nationcode": nationcode,
    "action": 'BINDPHONE'
  };
  return request({
    url: version2 + '/users/code',
    method: 'POST',
    data
  })
}

/**
 * 落地页订单状态查询
 */

export function activityPageOrder(id) {
  return request({
    url: version2 + '/activity/page/order/' + id,
    method: 'GET',
  })
}

/**
 * 获取微信配置
 * @param url
 */
export function wxConfig(url) {
  let params = { url: url };
  return request({
    url: version2 + '/users/wxconfig',
    method: 'GET',
    params
  })
}

//抖店卡券核销
export function couponsVerify(data) {
  return request({
    url: version3 + '/share/coupons/verify',
    method: 'POST',
    data
  })
}


//抖店卡券实物核销
export function entityVerify(data) {
  return request({
    url: version3 + '/share/entity/verify',
    method: 'POST',
    data
  })
}
//确认邀请
export function verificateUser(mobile, code) {
  let data = {
    mobile,
    code
  };

  return request({
    url: version1 + '/admin/ticket/write/off',
    method: 'POST',
    data
  })
}


// 验证码
export function getCode(mobile) {
  let data = {
    mobile,
    "action": "verification"
  };

  return request({
    url: version1 + '/send/code',
    method: 'POST',
    data
  })
}


//v1 做代理中转 后台接口在不同服务器
// 线下门票核销  nginx (正式服务器转发) 和 vue.config.js 做代理中转(本地调试)
export function checkTicket(code, mobile) {
  let data = {
    code,
    mobile
  };

  return request({
    url: version1 + '/ticket',
    method: 'POST',
    data
  })
}

